<template>
    <div>
        <page-header pageTitle="RESERVE.REALTORS_RESERVE" :haveSearch="true"
                     @searching="search($event)"></page-header>
        <div class="card" v-if="hastwoAllotments">
            <div class="card-body p-3">
                <div class="row gutters">
                    <div class="col-xl-3 col-md-3 col-6 col-sm-6">
                        <label>{{ t('NAV.ALLOTMENT') }}</label>
                        <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Loteamento' }" id="allotment"
                                 name="allotment" v-model="allotment_id" :disabled="!hastwoAllotments"
                                 :options="allotments" @select="changeAllotment($event)"/>
                    </div>
                    <div class="col-xl-3 col-md-3 col-6 col-sm-6">
                        <label>Ordenar Por:</label>
                        <Select2 :settings="{ width: '100%', placeholder: 'Selecione' }"
                                 :options="[{id: 'selecione', text: 'Selecione'}, ...optionsStatus]"
                                 @select="alterOrderBy"
                                 name="allotment" v-model="orderBy"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gutters" v-if="items?.data && (items?.data?.length || Object.keys(items.data).length)">
            <template v-for="item  in items.data">
                <div v-if="item.user_name" :key="item.id"
                     class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <figure class="user-card">
                        <figcaption>
                            <div :id="item.id"></div>
                        </figcaption>
                    </figure>
                </div>
            </template>
        </div>
        <div class="card" v-else>
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('RESERVE.MSG.NONE_RECORD_FIND') }}</h5>
                </div>
            </div>
        </div>
        <div class="row gutters">
            <div class="col-2" v-if="!widthMd(1000)"></div>
            <div :class="!widthMd(1000) ? 'col-8' : 'col-12'">
                <pagination-component v-if="items && items.data" :items="items" :to="'/corretores/reservas'"
                                      @changePage="renderChart(filter, $event)"></pagination-component>
            </div>
            <div class="col-2" v-if="!widthMd(1000)">
                <div class="row gutters d-flex justify-content-end" v-if="items && items.data && items.data[0]">
                    <div class="col-8">
                        <label>Por página: </label>
                        <Select2 :settings="{width: '100%'}" placeholder="Items por Página" @select="changePaginate"
                                 :options="paginate"
                                 id="paginate"
                                 name="paginate"
                                 v-model="perPage"
                        ></Select2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageHeader from "../../components/layouts/pageHeader";
import {mapState} from "vuex";
import Reserves from "../../services/Reserves";
import * as ApexCharts from "apexcharts";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Select2 from 'vue3-select2-component';
import PaginationComponent from "@/components/layouts/PaginationComponent";
import ReserveStatus from '../../services/ReserveStatus';
import widthMd from '../../mixins/widthMd';

export default {
    name: "indexRealtorsReserves",
    components: {
        pageHeader,
        Select2,
        PaginationComponent
    },
    mixins: [widthMd],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    computed: {
        ...mapState({
            allotments: 'allotments',
            hastwoAllotments: 'hastwoAllotments',
            paginate: 'paginate',
        }),
    },
    beforeRouteLeave(to, from, next) {
        if (!this.chart) {
            return next();
        } else {
            this.chart.forEach(item => item.destroy());
            this.chart = [];
            return next();
        }
    },
    data() {
        return {
            perPage: null,
            optionsStatus: [],
            orderBy: null,
            allotment_id: null,
            items: null,
            filters: null,
            chart: [],
        }
    },
    mounted() {
        if (localStorage.getItem('reserveAllotmentId')) {
            this.allotment_id = localStorage.getItem('reserveAllotmentId');
        }
        this.renderChart();
        this.getReservesStatus();
    },
    methods: {
        async changePaginate() {
            this.$store.commit('changeLoading', true);
            if (this.chart?.length) {
                this.chart?.forEach(chart => {
                    chart?.destroy();
                })
            }
            this.items = null;
            this.renderChart();
        },
        async alterOrderBy() {
            this.$store.commit('changeLoading', true);
            if (this.orderBy === 'selecione') {
                this.orderBy = null;
            }
            if (this.chart?.length) {
                this.chart?.forEach(chart => {
                    chart?.destroy();
                })
            }
            this.renderChart();
        },
        getReservesStatus() {
            ReserveStatus.list().then((resp) => {
                this.optionsStatus = resp.data;
            })
        },
        async index(filter, page) {
            if (!this.allotment_id) {
                try {
                    this.allotment_id = await this.allotments[0].id;
                } catch (e) {
                    this.$router.push({name: 'IndexReserve'});
                    return
                }
            }
            await Reserves.reservesByRealtor(this.allotment_id, filter, page, this.orderBy, this.perPage).then(resp => {
                resp.data.data = resp.data.data.filter((obj, index, self) =>
                        index === self.findIndex((o) => o.id === obj.id)
                );
                this.items = resp.data;
            });
        },
        changeAllotment(allotment) {
            this.allotment_id = allotment.id;
            if (this.chart.length) {
                this.chart.forEach(chart => {
                    chart.destroy();
                })
            }
            this.renderChart();
        },
        async search(filter) {
            this.$store.commit('changeLoading', true);
            this.chart.forEach(item => item.destroy());
            this.chart = [];
            this.filter = {filtro: filter};
            await this.renderChart(this.filter, 1);
            this.$store.commit('changeLoading', false);
        },
        plotGrafic(item, options) {
            let el = document.getElementById(item.id);
            let chart = new ApexCharts(el, options);
            chart.render();
            this.chart.push(chart);
        },
        async renderChart(filter = null, page = 1) {
            this.$store.commit('changeLoading', true);
            await this.index(filter, page);
            let item = JSON.parse(JSON.stringify(this.items.data));
            for (const key in item) {
                let iten = item[key];
                if (!iten?.user_name) {
                    continue
                }
                var options = {
                    series: [
                        iten.qtdReservesProgress,
                        iten.qtdReservesConfirmed,
                        iten.qtdReservesExpired,
                        iten.qtdReservesInterrupted
                    ],
                    chart: {
                        type: 'donut',
                        height: '190px',
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 10,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: `${iten?.user_name}${key}`,
                                    columnDelimiter: ',',
                                    headerCategory: 'category',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return new Date(timestamp).toDateString()
                                    }
                                },
                                svg: {
                                    filename: `${iten?.user_name}${key}`,
                                },
                                png: {
                                    filename: `${iten?.user_name}${key}`,
                                }
                            },
                            autoSelected: 'zoom'
                        },
                    },
                    title: {
                        text: iten?.user_name ? iten?.user_name : 'indefinido',
                        align: iten?.user_name?.length > 40 ? 'left' : 'center',
                        margin: 20,
                        style: {
                            fontSize: '13px',
                        },
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300,
                                height: 300,
                            },
                            legend: {
                                position: 'bottom',
                            }
                        }
                    }],
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                    },
                                    value: {
                                        show: true
                                    },
                                    total: {
                                        show: true,
                                        color: '#373d3f',
                                    }
                                }
                            }
                        }
                    },
                    legend: {
                        show: true,
                        fontSize: '14px',
                        horizontalAlign: 'left',
                        offsetY: 30,
                    },
                    labels: ['Em progresso', 'Confirmada', 'Expirada', 'Interrompida'],
                };
                this.plotGrafic(iten, options);
            }
            this.$store.commit('changeLoading', false);
        }
    }
}
</script>

<style scoped>
.apexcharts-legend {
    justify-content: start !important;
}
</style>